export interface IContent {
  id: string
  label: string
  description: string
}

export const content: IContent[] = [
  {
    id: '1',
    label:
      'When a shopping link is emailed to the homeowner, is it always automatically sent to the email of the person that is listed as "talking to"?',
    description: `No, it is not. Shopping link emails are sent to the email listed as the "buyer".`,
  },
  {
    id: '2',
    label: 'What if the member doesn’t have an email address on file?',
    description: `To send a shopping link with the member’s replacement options, an email is required. If the member does not have email, ask for a relative’s email to use temporarily and update the “buyer” email field and enter the temporary email address in the Appliance Option Notes in STAR. Once the member receives the shopping link email, remove the temporary email address from the “buyer” email field in STAR to prevent the recipient from receiving future emails.`,
  },
  {
    id: '3',
    label: 'How long is a shopping link active?',
    description: `The shopping link will expire 21 days from the date the link is emailed to the member.`,
  },
  {
    id: '4',
    label:
      'How do I send a member a shopping link if the appliance on the dispatch has been cancelled?',
    description: `To send a member a shopping link email, the status of all the appliances on the 
    dispatch must be updated from Cancel to Open in STAR.`,
  },
  {
    id: '5',
    label: 'How can I view the member’s shopping page?',
    description: `Open a new tab, copy the appliance portal URL including the member’s Replacement ID and the extension shop in this format /replacementID/shop OR go through dispatch lookup as normal and select “Order for Customer” button instead of “Send Replacement Offer”. NOTE: make sure to select the same like-for-like product or the customer’s CIL will change.`,
  },
  {
    id: '6',
    label: `Why am I getting this error when trying to view the member’s shopping page? The customer’s shopping page cannot be viewed while logged in as an agent. Please open a new tab in incognito mode (CTRL + SHIFT + N) 
      and paste the customer’s URL shopping link to view their shopping page.`,
    description: `The error is occurring because you are trying to view the member’s shopping page while logged into the appliance portal.
     A member’s shopping page can only be viewed in Incognito mode.`,
  },
  {
    id: '7',
    label:
      'How can I help a member who is seeing an error when trying to open their shopping link email?',
    description: `The member should try opening the email using a different device or browser.`,
  },
  {
    id: '8',
    label: 'How can a member request a CIL (cash-in-lieu)?',
    description: `The member will need to use the link in the shopping link email or survey email to access the form to request a cash-in-lieu.
     Members do not have the ability to request or accept a CIL through the portal.`,
  },
  {
    id: '9',
    label: 'How is CIL calculated in the portal?',
    description: `The member will need to use the link in the shopping link email or survey email to access the form to request a cash-in-lieu.
     Members do not have the ability to request or accept a CIL through the portal.`,
  },
  {
    id: '10',
    label: 'How Long Does It Take For Homeowners to Receive Their CIL?',
    description: `Checks are processed and mailed within 21 business days after the member accepts the CIL offer. 
    Shield Plus/Shield Complete members as well as members in Arizona, California, New Mexico and Washington, will 
    automatically be sent a check within 21 business days of the CIL Offer.`,
  },
  {
    id: '11',
    label: 'Will the homeowner\'s system be automatically covered moving forward?',
    description: `No. We require a proof of replacement receipt in order to reinstate coverage. The invoice must meet the following criteria:
        <br> • Must be legible
        <br> • Must be from the store you purchased the item from or the contractor who replaced it for you
        <br> • Must be a new replacement appliance
        <br> • Please add the new model number of the replacement appliance to the receipt
        <br> • Must clearly state the item that was purchased`,
  },
  {
    id: '12',
    label:
      'Why am I unable to send a homeowner a shopping link if they already accepted a CIL for another item on the same dispatch?',
    description: `If a homeowner accepts a reimbursement,
     the shopping link will no longer work for any other appliance on that same dispatch.`,
  },
  {
    id: '13',
    label:
      'When trying to email a homeowner a shopping link, I’m receiving an error indicating EMPTY_CUSTOMER: No Customer Data Is Available. What can I do to fix this?',
    description: `Confirm that the “buyer” email and phone number fields are populated with the homeowner’s information.`,
  },
  {
    id: '14',
    label:
      'Why is the portal showing a unit is now unavailable when the homeowner is ready to place the order but was showing it was available when I sent them the shopping link?',
    description: `When initially searching for availability, it shows items that have been cached to save time.
     To get the most updated availability, you will need to refresh the offer.`,
  },
  {
    id: '15',
    label: 'Can e-cards be cancelled if the homeowner changes their mind?',
    description: `No. Once an e-card is ordered, it cannot be cancelled.`,
  },
  {
    id: '16',
    label: 'What is the link to the Sears repair portal?',
    description: `The link to the Sears repair portal is <a class='text-primary-800' href='https://www.serviceorderportal.com/pages/home'>https://www.serviceorderportal.com/pages/home</a>`,
  },
  {
    id: '17',
    label:
      'Why can I not send replacement options to a homeowner on a dispatch that has more than one of the same appliances?',
    description: `You can only send a shopping link email to the first appliance on the dispatch.
     To do so, you will need to move the replacement option to the first appliance that needs to be replaced.`,
  },
  {
    id: '18',
    label:
      'Why am I unable to send a shopping link when an order has been cancelled? It states it has already been ordered.',
    description: `A new Part Request for the appliance will need to be created.`,
  },
]
