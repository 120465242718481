import React from 'react'
import View from './view/view'
import { PaymentProvider } from 'src/hooks/use-payment-context'
import { ShoppingCartProvider } from 'src/hooks/use-shopping-cart-context'
import { FormsProvider } from 'src/hooks/use-forms-context'
// Temporaty path =  /temp-payment
export const Payments = () => (
  <PaymentProvider>
    <ShoppingCartProvider>
      <FormsProvider>
        <View />
      </FormsProvider>
    </ShoppingCartProvider>
  </PaymentProvider>
)
