import yup, { valueSelected } from '../../../utils/yup'
import { textTemplatesEnglish } from 'src/i18n/text-templates-english'
import { MenuItem } from '@ftdr/blueprint-components-react'
import { MenuItemWithSubCategory } from 'src/utils/shared-types'

const { DEFAULT_ERROR } = textTemplatesEnglish
export const notFoundFormSchema = yup.object().shape({
  sku: yup.string().modelNumber(),
  price: yup.string().decimalAmountWithNegative(true),
  category: valueSelected(yup),
  description: yup.string().required(DEFAULT_ERROR),
  subCategory: yup.object().when('category', {
    is: (category: MenuItemWithSubCategory) => category.hasSubcategory,
    then: valueSelected(yup, true),
    otherwise: valueSelected(yup, false),
  }),
  subCategoryOther: yup.object().when(['subCategory', 'category'], {
    is: (subCategory: MenuItem, category: MenuItemWithSubCategory) =>
      (category?.hasSubcategory && subCategory.id === 'Other') ||
      subCategory.id === 'Other',
    then: valueSelected(yup, true),
    otherwise: valueSelected(yup, false),
  }),
})
