import React, { useMemo } from 'react'
import {
  Table,
  Pagination,
  SelectOption,
} from '@ftdr/blueprint-components-react'
import {
  TextComponent as Text,
  ProgressIndicatorComponent as ProgressIndicator,
} from 'src/components/custom-fdr-components'
import { v4 } from 'uuid'
import { useTabsTableContext } from 'src/hooks/use-tabs-table-context'
import { usePaymentColumns } from './use-payment-columns'
import { useHistory } from 'react-router'
import qs from 'query-string'
import { IUpdatedPagination } from 'src/utils/shared-types'

const PaymentTable: React.FC = () => {
  const history = useHistory()
  const { columns } = usePaymentColumns()
  const {
    activeTab,
    paymentTableDataIsLoading,
    paymentCurrentPagination,
    setPaymentCurrentPagination,
    paymentTableData,
    paymentTableDataError,
    paymentParams,
  } = useTabsTableContext()

  const onItemsPerPageChange = ({ value }: SelectOption) => {
    const updatedPagination = {
      page: 1,
      itemsPerPage: Number(value),
    }

    setPaymentCurrentPagination(updatedPagination)

    const stringParams = qs.stringify({
      ...paymentParams,
      page: updatedPagination.page,
      items_per_page: updatedPagination.itemsPerPage,
    })

    history.push(`/queue#tab${activeTab}?${stringParams}`)
  }

  // Trigers on click on pagination number
  const onPageChange = (page: number) => {
    const updatedPagination: IUpdatedPagination = {
      itemsPerPage: paymentCurrentPagination?.itemsPerPage,
      page,
    }

    if (paymentCurrentPagination?.page === page) {
      return
    }

    setPaymentCurrentPagination(updatedPagination)

    const stringParams = qs.stringify({
      ...paymentParams,
      page: updatedPagination.page,
      items_per_page: updatedPagination.itemsPerPage,
    })

    history.push(`/queue#tab${activeTab}?${stringParams}`)
  }

  const data = useMemo(() => {
    return paymentTableData?.map((items) => {
      const {
        cart,
        modelNumber,
      } = items
      return [
        {
          ...items,
          key: v4(),
          action: items,
          modelNumber: {modelNumber, cart},
        },
      ]
    })
  }, [paymentTableData])

  return (
    <>
      <div id="payments-table-wrapper">
        {paymentTableDataError && !paymentTableDataIsLoading && (
          <Text
            id="payment-table-error"
            color={paymentTableDataError !== 'No results' ? `error` : 'primary'}
          >
            {paymentTableDataError}
          </Text>
        )}
        {paymentTableDataIsLoading && (
          <div
            className="flex justify-center"
            id="payments-table-spinner-wrapper"
          >
            <ProgressIndicator size="medium" data-testid="spinner" />
          </div>
        )}

        {!paymentTableDataIsLoading &&
          !!paymentTableData?.length &&
          !paymentTableDataError && (
            <Table
              id="payment-table-table"
              data={data?.flat()}
              columns={columns}
              variant="heavy"
              striped="gray"
              paginate={false}
            />
          )}
      </div>
      {/* Pagination */}
      {!!paymentTableData?.length &&
      !paymentTableDataIsLoading &&
      !paymentTableDataError ? (
        <div
          className="-mx-4 sm:-mx-10 md:-mx-24 lg:-mx-48 lg:px-16 mt-8 justify-end lg:-mb-16 min-w-full"
          style={{
            background: '#FBFBFB',
            borderTop: '1px solid #EEEEEE',
            borderBottom: '1px solid #EEEEEE',
          }}
        >
          <Pagination
            id="payment-table-pagination"
            itemsPerPage={paymentCurrentPagination?.itemsPerPage || 9}
            hideViewAll
            totalItems={paymentCurrentPagination?.totalItems || 0}
            page={paymentCurrentPagination?.page || 0}
            onItemsPerPageChange={onItemsPerPageChange}
            onPageChange={onPageChange}
            itemsPerPageOptions={[
              { value: '9' },
              { value: '30' },
              { value: '60' },
            ]}
          />
        </div>
      ) : null}
    </>
  )
}

export default PaymentTable
