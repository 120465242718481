import { Dialog, useAppContext } from '@ftdr/blueprint-components-react'
import {
  ButtonComponent as Button,
  TextComponent as Text,
} from '../custom-fdr-components'
import React from 'react'
import { TrimKitDetailPage } from './trim-kit-detail-page'

interface Props {
  onClose: () => void
  product: string
}
export const TrimKitDialog = ({ onClose, product }: Props) => {
  const {
    appSettings: { localizedText },
  } = useAppContext()

  return (
    <Dialog
      id="trim-kit-dialog-wrapper"
      open
      modal
      className="overflow-auto w-auto"
      data-testid="TrimKitDialogComponent"
      onClose={onClose}
      header={<Text variant="heading-04">{product}</Text>}
      actions={[
        <Button
          id="faq-dialog-close"
          variant="outlined"
          color="gray"
          size="small"
          label={localizedText('FAQ_CLOSE_BTN')}
          data-testid="TrimKitDialogCloseButton"
          onClick={onClose}
        />,
      ]}
    >
      <TrimKitDetailPage trimKit={product} />
    </Dialog>
  )
}
