import { Breadcrumbs, useAppContext } from '@ftdr/blueprint-components-react'
import {
  TextComponent as Text,
  ProgressIndicatorComponent as ProgressIndicator,
} from 'src/components/custom-fdr-components'
import React, { useEffect, useState } from 'react'
import { api } from 'src/utils/api'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import Carousel from 'src/components/carousel/carousel'
import OrderProgress from './order-progress'
import { useMedia } from '../../hooks/use-media'
import { SelectedSlideProvider } from 'src/hooks/use-slide-context'

interface LocationState {
  vendor: string
  orderID: string
  purchaseOrder: string
}

export const TrackingOrderDetails = ({
  vendor,
  orderID,
  purchaseOrder,
}: LocationState) => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const [requestStatus, setRequestStatus] = useState('loading')
  const [orderDate, setOrderDate] = useState('')
  const [deliveryDate, setDeliveryDate] = useState('')

  const [
    orderStatus,
    setOrderStatus,
  ] = useState<appliancepb.OrderStatus | null>(null)

  const [product, setProduct] = useState<appliancepb.Product | null>(null)
  const isDesktop = useMedia('(min-width:1366px)')
  const fetchData = async (vendor: string, id: string, purchase: string) => {
    try {
      const status = await api.getOrderStatus(vendor, id, purchase)
      const orderDate = new Date(status.orderDate?.seconds * 1000)
      const deliveryDate = new Date(status.deliveryDate?.seconds * 1000)
      setOrderStatus(status)
      setOrderDate(orderDate.toDateString())
      setDeliveryDate(deliveryDate.toDateString())
      setProduct(status.product as appliancepb.Product)
      setRequestStatus('ok')
    } catch (err: any) {
      if (err.response.status === 400) {
        setRequestStatus('not found')
      } else {
        setRequestStatus('error')
      }
    }
  }

  useEffect(() => {
    fetchData(vendor, orderID, purchaseOrder)
  }, [vendor, orderID, purchaseOrder])

  if (requestStatus === 'error') {
    return <Text>{localizedText('TRACKING_DETAILS_ORDER_ERROR')}</Text>
  }

  if (requestStatus === 'not found') {
    return <Text>{localizedText('TRACKING_DETAILS_ORDER_NOT_FOUND')}</Text>
  }

  return (
    <div className="overflow-hidden -mt-8 lg:-mt-0">
      <div className="-mx-6 lg:mx-0">
        <Breadcrumbs
          className="mb-6"
          currentPageLabel={localizedText(
            'TRACKING_DETAILS_BREADCRUMB_CURRENT'
          )}
          links={[
            {
              id: 'breadcrumb',
              href: '/status',
              children: localizedText('TRACKING_DETAILS_BREADCRUMB_CHILD'),
              underline: 'hover',
            },
          ]}
        />
      </div>
      {requestStatus === 'loading' ? (
        <div id="tracking-order-details-spinner-wrapper" className="mb-8">
          <ProgressIndicator size="medium" data-testid="spinner" />
        </div>
      ) : (
        <>
          <div className="mb-8">
            <Text id="tracking-order-t-status-1" className="mb-1">
              {`${localizedText('TRACKING_DETAILS_ORDERID_LABEL')} ${
                orderStatus?.orderID
              }`}
            </Text>
            <Text
              id="tracking-order-t-status-2"
              variant={`${isDesktop ? 'heading-02' : 'heading-03'}`}
              className="mb-1"
            >
              {orderStatus?.status}
            </Text>
            {orderDate && orderStatus?.status !== 'Order Status Not Available' && (
              <>
                  <Text
                    id="tracking-order-t-order-date-1"
                    variant="heading-04"
                    className="font-light mb-1"
                  >
                    {`${localizedText(
                      'TRACKING_DETAILS_ORDER_DATE_LABEL'
                    )}: ${orderDate}`}
                  </Text>
              </>
            )}
            {deliveryDate && orderStatus?.status !== 'Order Status Not Available' && (
              <>
                {orderStatus!.progress < 3 && (
                  <Text
                    id="tracking-order-t-order-date-2"
                    variant="heading-04"
                    className="font-light"
                  >
                    {`${localizedText(
                      'TRACKING_DETAILS_DELIVERY_DATE_LABEL'
                    )}: ${deliveryDate}`}
                  </Text>
                )}
                {orderStatus!.progress === 3 && (
                  <Text
                    id="tracking-order-t-order-date-3"
                    variant="heading-04"
                    className="font-light"
                  >
                    {`${localizedText(
                      'TRACKING_DETAILS_DELIVERY_DATE_LABEL_DELIVERED'
                    )}: ${deliveryDate}`}
                  </Text>
                )}
              </>
            )}
          </div>
          <div className="my-8 flex justify-center">
            <div className="w-9/12">
              <OrderProgress status={orderStatus?.progress as number} />
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="shrink-0 w-full md:w-1/2 order-2 md:order-1 mt-6 md:mt-0">
              <Text variant="heading-05" className="mb-4">
                {localizedText('TRACKING_DETAILS_ORDERINFO_LABEL')}
              </Text>
              <div className="grid gap-2">
                <div>
                  <Text>{localizedText('TRACKING_DETAILS_ITEM_LABEL')}</Text>
                  <Text id="tracking-order-t-description" className="font-bold">
                    {orderStatus?.product?.description}
                  </Text>
                </div>
                <div>
                  <Text>{localizedText('TRACKING_DETAILS_VENDOR_LABEL')}</Text>
                  <Text id="tracking-order-t-vendor" className="font-bold">
                    {orderStatus?.vendorName}
                  </Text>
                </div>
                <div>
                  <Text>
                    {localizedText('TRACKING_DETAILS_ORDERID_LABEL')}{' '}
                  </Text>
                  <Text id="tracking-order-t-order-id" className="font-bold">
                    {orderStatus?.orderID}
                  </Text>
                </div>
                <div>
                  <Text>
                    {localizedText('TRACKING_DETAILS_DISPATCH_LABEL')}
                  </Text>
                  <Text id="tracking-order-t-dispatch-id" className="font-bold">
                    {orderStatus?.dispatchID}
                  </Text>
                </div>
                <div>
                  <Text>
                    {localizedText('TRACKING_DETAILS_PARTREQUEST_LABEL')}
                  </Text>
                  <Text id="tracking-order-t-part-req" className="font-bold">
                    {orderStatus?.partRequestID}
                  </Text>
                </div>
              </div>
            </div>
            {product && (
              <div
                id="tracking-order-carousel-wrapper"
                className="w-full md:w-1/2 md:grow-1 order-1 md:order-2"
                style={{
                  height: isDesktop ? 'auto' : 'auto',
                  paddingRight: isDesktop
                    ? 'calc(( 100% - 9 / 12 * 100% ) / 2 )'
                    : 0,
                }}
              >
                <SelectedSlideProvider>
                  <Carousel product={product} />
                </SelectedSlideProvider>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}
