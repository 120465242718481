import React from 'react'
import ScheduledBanners from './sheduled-banners'
import AddBanners from './add-banners'
import EditModalBanner from './edit-modal-banner'

const Banners = () => {
  return (
    <>
      <ScheduledBanners />
      <AddBanners id="main-banners" title="ADMIN_TITLE_BANNERS_0" />
      <EditModalBanner />
    </>
  )
}

export default Banners
