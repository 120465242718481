import React, { useEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { api } from '../../utils/api'
import { ProgressIndicatorComponent as ProgressIndicator } from '../custom-fdr-components'
import { TextComponent as Text } from '../custom-fdr-components'
import { appliancepb } from '../../services/protobuf-models/appliance-ms-protobuf-models'
import Carousel from 'src/components/carousel/carousel'
import { useSelector, useDispatch } from 'react-redux'
import { addReplacement } from 'src/store/compare-store'
import { AppState } from 'src/utils/shared-types'
import { useMedia } from '../../hooks/use-media'
import DeliveryDate from '../../components/delivery-date/delivery-date'
import { SelectedSlideProvider } from 'src/hooks/use-slide-context'
import { ProductFeatures } from 'src/screens/product-detail-page/product-features'
import { ProductAttributes } from 'src/screens/product-detail-page/product-attributes'

interface ProductDetailParams {
  replacementId: string
}

type Props = {
  trimKit: string
}

type NullableProduct = appliancepb.Product | null
export const TrimKitDetailPage = ({ trimKit }: Props) => {
  const trimKitSize = ''
  const icemakerAddon = ''
  const dispatch = useDispatch()
  const productId = trimKit
  const { replacementId } = useParams<ProductDetailParams>()
  const [product, setProduct] = useState<NullableProduct>(null)
  const [isGiftCard, setIsGiftCard] = useState<boolean>(false)
  const [error, setError] = useState<any>()
  const itemId = useSelector(
    (state: AppState) => state.survey.agentSurvey.item?.id
  )
  const dcov = useSelector((state: AppState) => state.survey.agentSurvey.dcov)

  const partRequestId = useSelector(
    (state: AppState) =>
      state.survey.agentSurvey.partRequestId ||
      state.replacement.data?.replacementDetails?.partRequestID
  )
  const addressId = useSelector(
    (state: AppState) =>
      state.survey.agentSurvey.dispatch?.addressID ||
      state.replacement.data?.customer?.address?.ID
  )
  const id = replacementId || itemId || ''
  const { unit, height, width, depth } = product?.dimensions || {}
  const parsedUnit = unit === 'in' ? 'inches' : unit || ''
  const isDesktop = useMedia('(min-width:1024px)')
  useEffect(() => {
    dispatch(addReplacement(replacementId))
    //eslint-disable-next-line
  }, [])
  useEffect(() => {
    const getProduct = async () => {
      // TODO: rename this method in api
      try {
        const product = await api.getReplacementProduct(
          id,
          productId,
          addressId || '',
          partRequestId || '',
          dcov,
          trimKitSize,
          icemakerAddon
        )

        setIsGiftCard(/^BH-/.test(product?.ID as string))
        setProduct(product)
        setError(null)
      } catch (err) {
        console.error(err)
        setError(err)
      }
    }
    getProduct()
    //eslint-disable-next-line
  }, [
    id,
    productId,
    addressId,
    partRequestId,
    dcov,
    trimKitSize,
    icemakerAddon,
  ])

  const attributes = useMemo<appliancepb.Product.IAttributeValuesPair[]>(() => {
    if (!product) return []
    return product.attributeGroups.reduce(
      (acc: appliancepb.Product.IAttributeValuesPair[], group) =>
        group.attributes ? [...acc, ...group.attributes] : acc,
      []
    )
  }, [product])

  return (
    <>
      <section className="">
        {product ? (
          /* product info */
          <article className="mt-4 relative">
            <div className="mb-4 lg:mb-4">
              <Text
                id="product-detail-t-description"
                variant={`${isDesktop ? 'heading-02' : 'heading-04'}`}
              >
                {product.description}
              </Text>
              <Text id="product-detail-t-ID" className="helper-text">
                {product.ID}
              </Text>
            </div>
            <div className="lg:grid grid-cols-2 col-gap-24">
              {/* images */}
              <div
                className="lg:order-2 mb-4 lg:mb-0 flex w-full self-start"
                id="product-detail-page-carousel"
              >
                <SelectedSlideProvider>
                  <Carousel product={product} isClickable={true} />
                </SelectedSlideProvider>
              </div>
              <div className="lg:order-1">
                {/* dimensions */}
                {!isGiftCard && (
                  <div className="mb-2">
                    <Text
                      id="product-detail-page-detail-sizes"
                      variant="heading-05"
                      textTemplateKey="PRODUCT_DETAILS_DIMENSIONS"
                      textTemplateData={{ unit: parsedUnit }}
                    />
                    <Text variant="heading-05">{`${height}h x ${width}w x ${depth}d`}</Text>
                  </div>
                )}
                {/* price */}
                <div className="mb-2">
                  {!replacementId && product.IsCoupa && (
                    <Text
                      id="product-detail-product-coupa"
                      variant="caption"
                      className="font-bold mb-1"
                      color="success"
                      textTemplateKey="PRODUCT_COUPA"
                    />
                  )}
                  {!product.IsAvailableForZip && (
                    <Text
                      id="product-detail-page-product-for-zip"
                      variant="heading-05"
                      color="error"
                      textTemplateKey="PRODUCT_UNAVAILABLE"
                    />
                  )}
                  <DeliveryDate product={product} isHeader={true} />
                </div>
                {/* features */}
                <div>
                  <Text
                    variant="heading-05"
                    textTemplateKey="PRODUCT_DETAIL_FEATURES_TITLE"
                  />
                  <ProductFeatures features={product.bulletFeatures} />
                </div>
              </div>
            </div>
            {/* attributes */}
            <ProductAttributes
              id="product-detail-page"
              attributes={attributes}
            />
          </article>
        ) : error ? (
          <div className="mt-8 flex justify-center">
            <Text
              id="product-detail-page-agent-customer-error"
              variant="heading-06"
              textTemplateKey={replacementId ? 'ERROR_CUSTOMER' : 'ERROR_AGENT'}
            />
          </div>
        ) : (
          /* loading */
          <div
            className="mt-8 flex justify-center"
            id="product-detail-page-spinner-wrapper"
          >
            <ProgressIndicator size="medium" data-testid="spinner" />
          </div>
        )}
      </section>
    </>
  )
}
