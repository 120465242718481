import { format } from 'date-fns'
import { states } from '../states'
import { google } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
const DEFAULT_LOCALE = 'en-US'
const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy'

interface CurrencyOptions {
  locale?: string
  localeMatcher?: string
  style?: string
  currency?: string
  currencyDisplay?: string
  useGrouping?: boolean
  minimumIntegerDigits?: number
  minimumFractionDigits?: number
  maximumFractionDigits?: number
  minimumSignificantDigits?: number
  maximumSignificantDigits?: number
}
/**
 * Format cents into currency
 * @param num - Number in cents
 * @param options - Currency options
 */
export const formatCentsCurrency = (
  num: number,
  options?: CurrencyOptions
): string => {
  const localeToUse =
    options?.locale || window.navigator.language || DEFAULT_LOCALE
  return new Intl.NumberFormat(localeToUse, {
    style: 'currency',
    currency: 'USD', // should currency change with the navigator language as well?
    ...options,
  }).format(num / 100)
}

/**
 * Format phone number
 * @param num - Phone number
 */
export const formatPhone = (num?: number | string | null) => {
  if (!num) return ''
  const clean = num.toString().replace(/\D/g, '')
  const matches = clean.match(/(\d{3})(\d{3})(\d{4})/)
  if (!matches) return ''
  return matches.slice(1, 4).join('-')
}

export const today = new Date()
export const yesterday = new Date(
  new Date(today.setDate(today.getDate() - 1)).setUTCHours(12, 0, 0, 0)
)
export const minDateForSummary = new Date('2022-06-21T00:00:00')
export const MSTTimezoneOffset = -6 * 60 * 60

export const formatDate = (
  param: number | string | Date,
  dateFormat: string = DEFAULT_DATE_FORMAT
) => {
  const date = param instanceof Date ? param : new Date(param)
  return format(date, dateFormat)
}

export const formatBannerDate = (
  timestamp: google.protobuf.ITimestamp | undefined | null
) => {
  if (!timestamp?.seconds) return ''

  const ms = timestamp.seconds * 1000

  return formatDate(new Date(ms))
}

export const formatTimestampToDate = (
  timestamp: google.protobuf.ITimestamp | undefined | null,
  dateFormat: string = DEFAULT_DATE_FORMAT,
  timeZone?: string
) => {
  if (!timestamp) {
    return ''
  }
  if (!timestamp?.nanos) {
    timestamp!.nanos = 0
  }
  const ms = timestamp!.seconds * 1000 + timestamp!.nanos / 1e6
  if (timeZone) {
    return formatDateWithTimeZone(new Date(ms), timeZone)
  } else {
    return formatDate(new Date(ms), dateFormat)
  }
}
export const formatDeliverDate = (seconds: number) => {
  return formatDate(new Date(seconds * 1000))
}
export const formatSecondsToDate = (seconds: number) => {
  return new Date(seconds * 1000)
}
export const formatInputToDollars = (input: string) => {
  let value = input
  value = value.replace(/[^\d.]/g, '')

  if (value === '' || isNaN(value as any)) {
    value = '0.00'
  }

  const options = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }

  value =
    value || value === '0'
      ? new Intl.NumberFormat('en-US', options).format(parseFloat(value))
      : ''

  return value
}

export const formatPriceToCents = (value: string) => {
  const price = value.replace(/[^\d.]/g, '')
  const finalPrice = parseFloat(price) * 100
  return finalPrice
}

export const formatBilingStateCode = (code: string) =>
  states.find((element) => element.id === code)?.label

const formatDateWithTimeZone = (date: Date | string, timeZone: string) => {
  const config: Intl.DateTimeFormatOptions = {
    timeZone,
    hour12: false,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
  }
  if (typeof date === 'string') {
    return new Date(date).toLocaleString('en-US', config).replace(',', '')
  }

  return date.toLocaleString('en-US', config).replace(',', '')
}
