import React, { createContext, useEffect, useContext, useState } from 'react'
import { useMedia } from '../hooks/use-media'

type Props = {
  open: boolean
  setOpen(isOpen: boolean): void
}
type TMenuContext = {
  children?: React.ReactNode
}
const MenuContext = createContext<Props>({} as Props)

const MenuProvider: React.FC<TMenuContext> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false)
  const value = useMedia('(min-width:1138px)')
  useEffect(() => {
    value && setOpen(false)
  }, [value])
  return (
    <MenuContext.Provider value={{ open, setOpen }}>
      {children}
    </MenuContext.Provider>
  )
}

const useMenuContext = () => useContext(MenuContext)

export { MenuProvider, useMenuContext }
