import { Dialog, useAppContext } from '@ftdr/blueprint-components-react'
import {
  TextComponent as Text,
  ButtonComponent as Button,
} from '../custom-fdr-components'
import React from 'react'
import { useSelector } from 'react-redux'
import { AppState, Tenant } from 'src/utils/shared-types'
import { useMedia } from 'src/hooks/use-media'

interface Props {
  onClose: () => void
}
export const FaqDialog = ({ onClose }: Props) => {
  const isDesktop = useMedia('(min-width:1024px)')
  const {
    appSettings: { localizedText },
  } = useAppContext()

  const tenant = useSelector(
    (state: AppState) =>
      state.survey.agentSurvey.dispatch?.tenantAbbreviation || Tenant.AHS
  )

  return (
    <Dialog
      id="faq-dialog-wrapper"
      open
      modal
      closeOnOutsideClick
      className="overflow-auto"
      data-testid="FaqDialogComponent"
      onClose={onClose}
      header={<Text variant="heading-04" textTemplateKey={'FAQ_HEADER'} />}
      actionsAlign={isDesktop ? 'right' : 'left'}
      actions={[
        <div className="pb-8 lg:pb-0">
          <Button
            id="faq-dialog-close"
            color="gray"
            variant="outlined"
            size="small"
            label={localizedText('FAQ_CLOSE_BTN')}
            data-testid="FaqDialogCloseButton"
            onClick={onClose}
          />
        </div>,
      ]}
    >
      <ul className="list-disc px-6">
        <li>
          <Text variant="heading-05" textTemplateKey="FAQ_1_TITLE" />
          <Text variant="body-long" textTemplateKey="FAQ_1_BODY" />
        </li>
        <li>
          <Text variant="heading-05" textTemplateKey="FAQ_2_TITLE" />
          <Text textTemplateKey="FAQ_2_BODY" textTemplateData={{ tenant }} />
        </li>
        <li>
          <Text
            variant="heading-05"
            textTemplateKey="FAQ_3_TITLE"
            textTemplateData={{ tenant }}
          />
          <Text textTemplateKey="FAQ_3_BODY" textTemplateData={{ tenant }} />
        </li>
        <li>
          <Text variant="heading-05" textTemplateKey="FAQ_4_TITLE" />
          <Text textTemplateKey="FAQ_4_BODY" />
        </li>
        <li>
          <Text
            variant="heading-05"
            dangerouslySetInnerHTML={{
              __html: localizedText('FAQ_5_TITLE'),
            }}
          />
        </li>
      </ul>
    </Dialog>
  )
}
