import { Dialog, useAppContext } from '@ftdr/blueprint-components-react'
import {
  TextComponent as Text,
  ButtonComponent as Button,
} from 'src/components/custom-fdr-components'
import React, { PropsWithChildren, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addReplacement } from 'src/store/replacemnt-survey-store'
import { useHistory } from 'react-router-dom'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import LinkGenarator from './link-generator'
import { addInfo } from 'src/store/survey-store'
import { api } from 'src/utils/api'
interface IProps extends PropsWithChildren {
  status: string
  shopLink: string
  id: string
  item: appliancepb.IFullReplacementDetails
}
const ShoppingSurveyDialog: React.FC<IProps> = ({
  status,
  id,
  item,
  shopLink,
}) => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const dispatch = useDispatch()
  const history = useHistory()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  return (
    <>
      <LinkGenarator
        callback={setIsModalOpen}
        status={status}
        id={id}
        shopLink={shopLink}
      />

      <Dialog
        id="shopping-survey-dialog"
        open={isModalOpen}
        modal={true}
        onClose={() => setIsModalOpen(false)}
        className="mt-auto mb-auto"
        usePortal={true}
        header={
          <Text
            variant="heading-04"
            textTemplateKey={
              status === 'CONFIRMED'
                ? localizedText('QUEUE_SHOPPING_MODAL_TITLE_1')
                : localizedText('QUEUE_SHOPPING_MODAL_TITLE_2')
            }
          ></Text>
        }
      >
        <div>
          <Text className="mb-4 pr-12">
            {status === 'CONFIRMED'
              ? localizedText('QUEUE_SHOPPING_MODAL_DESCRIPTION_1')
              : localizedText('QUEUE_SHOPPING_MODAL_DESCRIPTION_2')}
          </Text>
          {status === 'CONFIRMED' ? (
            <div className="flex py-4">
              <Button
                size="small"
                label={localizedText('QUEUE_TABLE_ORDER_ITEMS')}
                className="mr-4"
                onClick={(e) => {
                  e.preventDefault()
                  dispatch(addInfo(null))
                  dispatch(addReplacement(item))
                  history.push('/temp-payment/#continue-replacement')
                }}
                disabled={!api.config.CONTINUE_REPLACEMENT}
              />

              <Button
                size="small"
                label={localizedText('QUEUE_TABLE_GO_SHOPPING')}
                href={shopLink}
              />
            </div>
          ) : null}
          {status === 'ORDERED' ? (
            <div className="flex py-4">
              <Button
                size="small"
                label={localizedText('QUEUE_TABLE_ACCESSORY')}
                className="mr-4"
                onClick={(e) => {
                  e.preventDefault()
                  dispatch(addInfo(null))
                  dispatch(addReplacement(item))
                  history.push('/temp-payment/#accessories')
                }}
              />
              <Button
                size="small"
                color="interactive"
                label={localizedText('QUEUE_TABLE_RESTOCKING')}
                onClick={(e) => {
                  e.preventDefault()
                  dispatch(addInfo(null))
                  dispatch(addReplacement(item))
                  history.push('/temp-payment/#restocking-fee')
                }}
              />
            </div>
          ) : null}
        </div>
      </Dialog>
    </>
  )
}

export default ShoppingSurveyDialog
