import React, { useEffect } from 'react'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import { AppState } from 'src/utils/shared-types'
import { useDispatch, useSelector } from 'react-redux'
import { clearSaveError, sendBanner } from 'src/store/admin-store'
import {
  Select,
  TextArea,
  useAppContext,
  DateTimeInput,
} from '@ftdr/blueprint-components-react'
import {
  ButtonComponent as Button,
  TextComponent as Text,
} from '../../custom-fdr-components'
import { useAdminContext } from '../../../hooks/use-admin-context'
import {
  initialBannersMessage,
  emptyItem,
  initialBannersTypes,
} from '../initials'
import { MenuItem, MenuItemType } from 'src/utils/shared-types'
import { formatSecondsToDate } from '../../../utils/internationalization-helper/internationalization-helper'
import {
  convertToServerTimeZone,
  clockModeRadios,
  dateValidation,
} from '../helpers'

import './reset.css'

interface IProps {
  title: string
  action?: (banner: appliancepb.IBanner) => void
  id: string
}
const AddBanners: React.FC<IProps> = ({ title, action: editFn, id }) => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const dispatch = useDispatch()
  const { saveLoading, saveError, editError, editLoading } = useSelector(
    (state: AppState) => state.admin
  )
  const {
    clearBanner,
    banner,
    saveBanner,
    isSaveBunnerDisabled,
    bannerCustomMessage,
    setBannerCustomMessage,
    dateError,
    setDateError,
  } = useAdminContext()
  useEffect(() => {
    dispatch(clearSaveError())
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (banner?.activate?.seconds > 0 && banner?.expire?.seconds > 0) {
      dateValidation(
        new Date(banner?.activate?.seconds * 1000),
        new Date(banner?.expire?.seconds * 1000)
      )
        ? setDateError('')
        : setDateError('The expiry date should be greater than the start date.')
    }
    //eslint-disable-next-line
  }, [banner?.expire?.seconds, banner?.activate?.seconds])

  return (
    <div className="pb-4">
      <Text
        id={`${id}-add-banners-title`}
        className="font-display font-bold text-4 lg:text-4 mb-8"
        textTemplateKey={title}
      />

      <Select
        id={`${id}-add-banners-type`}
        required
        formField
        label={localizedText('ADMIN_LABEL_TYPE')}
        className="w-full md:w-56 mb-6"
        options={initialBannersTypes || null}
        selected={
          initialBannersTypes.find(({ id }) => +id === banner?.severity) ||
          editFn
            ? initialBannersTypes.find(({ id }) => +id === banner?.severity) ||
              initialBannersTypes[0]
            : emptyItem
        }
        onSelect={(item) => {
          const option = { ...item } as MenuItemType
          saveBanner({ severity: +option.id })
        }}
      />
      <div style={{ maxWidth: 560 }} className="mb-5">
        {editFn && (
          <Select
            id={`${id}-add-banners-message`}
            required
            formField
            label={localizedText('ADMIN_LABEL_MESSAGE')}
            className="w-full mb-6"
            options={initialBannersMessage || null}
            selected={
              initialBannersMessage.find(
                ({ label }) => label === banner?.message
              ) || initialBannersMessage[2]
            }
            onSelect={(item) => {
              const option = { ...item } as MenuItem
              option.label !== initialBannersMessage[2].label &&
                setBannerCustomMessage('')
              saveBanner({ message: option.label })
            }}
          />
        )}
        {editFn &&
        banner?.message &&
        (!initialBannersMessage
          .map((value) => value.label)
          .includes(banner?.message) ||
          banner?.message === initialBannersMessage[2].label) ? (
          <TextArea
            id={`${id}-add-banners-descritpion`}
            className="w-full mb-6"
            formField={true}
            label="Add Message"
            textAlign="left"
            value={bannerCustomMessage}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
              setBannerCustomMessage(event.target.value)
            }}
          />
        ) : null}
        {!editFn && (
          <Select
            id={`${id}add-banners-message-area`}
            required
            formField
            label={localizedText('ADMIN_LABEL_MESSAGE')}
            className="w-full mb-6"
            options={initialBannersMessage || null}
            selected={
              initialBannersMessage.find(
                ({ label }) => label === banner?.message
              ) || emptyItem
            }
            onSelect={(item) => {
              const option = { ...item } as MenuItem
              option.label !== initialBannersMessage[2].label &&
                setBannerCustomMessage('')
              saveBanner({ message: option.label })
            }}
          />
        )}

        {!editFn &&
        initialBannersMessage.find(({ label }) => label === banner?.message)
          ?.value === 'user-message' ? (
          <TextArea
            id={`${id}-add-banners-message-area-1`}
            className="w-full mb-6"
            formField={true}
            label="Add Message"
            textAlign="left"
            value={bannerCustomMessage}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
              setBannerCustomMessage(event.target.value)
            }}
          />
        ) : null}

        <DateTimeInput
          id={`${id}-add-banners-date-in`}
          className="add-banner-date w-full md:w-56 mb-6"
          label="Start date"
          datePicker
          clockMode={clockModeRadios.values[0]}
          showDateClearButton
          showMonths={1}
          minDate={convertToServerTimeZone()}
          selectedDate={
            banner?.activate?.seconds
              ? new Date(formatSecondsToDate(banner?.activate?.seconds))
              : convertToServerTimeZone()
          }
          onDateSelect={(start) => {
            saveBanner({
              activate: {
                seconds: start?.getTime() ? start?.getTime() / 1000 : 0,
                nanos: 0,
              },
            })
          }}
        />
        <DateTimeInput
          id={`${id}-add-banners-date-out`}
          error={dateError}
          className="add-banner-date w-full md:w-56 mb-6"
          label="End Date"
          datePicker
          clockMode={clockModeRadios.values[0]}
          showDateClearButton
          showMonths={1}
          minDate={
            banner?.activate?.seconds
              ? new Date(formatSecondsToDate(banner?.activate?.seconds))
              : convertToServerTimeZone()
          }
          selectedDate={
            banner?.expire?.seconds
              ? new Date(formatSecondsToDate(banner?.expire?.seconds))
              : null
          }
          onDateSelect={(end) => {
            saveBanner({
              expire: {
                seconds: end?.getTime() ? end?.getTime() / 1000 : 0,
                nanos: 0,
              },
            })
          }}
        />
      </div>
      <Button
        id={`${id}-add-banners-save`}
        label={localizedText('ADMIN_LABEL_SAVE')}
        size="medium"
        loading={!editFn ? saveLoading : editLoading}
        onClick={() => {
          if (!editFn) {
            dispatch(
              sendBanner({
                ...banner,
                message: bannerCustomMessage || banner?.message,
              })
            )
            if (!saveError) {
              clearBanner()
              setBannerCustomMessage('')
            }
          } else {
            editFn({
              ...banner,
              message: bannerCustomMessage || banner?.message,
            })
          }
        }}
        disabled={!!dateError || isSaveBunnerDisabled}
      />
      {!editFn && !!saveError && (
        <p className="text-error mt-2 absolute">{saveError}</p>
      )}
      {editFn && !!editError && (
        <p className="text-error mt-2 absolute">{editError}</p>
      )}
    </div>
  )
}

export default AddBanners
