import { Input } from '@ftdr/blueprint-components-react'
import { ButtonComponent as Button } from 'src/components/custom-fdr-components'
import { TextComponent as Text } from 'src/components/custom-fdr-components'
import React, { ChangeEvent, useState } from 'react'
import { usePaymentModalContext } from 'src/hooks/use-payment-modal-context'
import { api } from 'src/utils/api'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import { isOrderID } from 'src/utils/validation-utils'
import { RequestStatus } from 'src/utils/shared-types'

interface IRecordExternalOrderModal {
  onClose: () => void
}

export const RecordExternalOrderField: React.FC<IRecordExternalOrderModal> = ({
  onClose,
}) => {
  const { recordExternalOrderModal: data } = usePaymentModalContext()
  const [externalOrderNumber, setExternalOrderNumber] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [status, setStatus] = useState<RequestStatus>('pending')

  const dataToSend: appliancepb.IOutrightPaymentPatchRequest = {
    vendorOrderNumber: externalOrderNumber,
    status: 2,
  }

  const handleOrderNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    setExternalOrderNumber(event.target.value)
    setStatus('pending')
    if (!isOrderID(event.target.value)) {
      setError(
        'Order ID must be between 6 and 45 characters long and contain only numbers and letters'
      )
    } else {
      setError('')
    }
  }

  const handleSubmit = async () => {
    setStatus('loading')
    try {
      await api.recordExternalOrder(data.id!, dataToSend)
      setStatus('ok')
    } catch (err: any) {
      setError(err.message)
      setStatus('error')
    }
  }

  const handleCancel = () => {
    onClose()
  }

  return (
    <div>
      <Input
        id="record-external-order-input"
        formField
        value={externalOrderNumber}
        label={'Record External Order'}
        onChange={handleOrderNumberChange}
        error={error}
      />
      {status === 'ok' && (
        <Text
          id={`record-external-order-success`}
          variant="helper-text"
          color="success"
        >
          Success!
        </Text>
      )}

      <Button
        id="record-external-order-button-submit"
        type="submit"
        label="Submit"
        className="mt-8 w-full md:w-auto flex lg:inline-block mr-10"
        disabled={externalOrderNumber.length === 0 || !!error}
        loading={status === 'loading'}
        onClick={handleSubmit}
      />
      <Button
        id="record-external-order-button-close"
        type="reset"
        label="Cancel"
        variant="outlined"
        className="mt-8 w-full md:w-auto flex lg:inline-block ml-10"
        loading={status === 'loading'}
        onClick={handleCancel}
      />
    </div>
  )
}
