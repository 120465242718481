import React, { useEffect } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { Browse } from 'src/screens/browse/'
import QueueTabsLayout from 'src/screens/exception-queue/queue-tabs-layout/queue-tabs-layout'
import { AgentSurveyView } from 'src/screens/agent-survey'
import { OutrightPayment } from 'src/screens/outright-payment/outright-payment'
import { Payments } from 'src/screens/outright-payment-temp'
import { ProductDetailPage } from 'src/screens/product-detail-page'
import { CheckoutPage } from 'src/screens/checkout-page'
import { ConfirmationPage } from 'src/screens/confirmation-page'
import AgentFaq from 'src/screens/agent-faq/agent-faq'
import { AgentReplacementConfirmationView } from 'src/screens/agent-replacement-confirmation'
import { MakeAndModel } from 'src/screens/make-and-model'
import { Login } from 'src/screens/login'
import { VerificationFailure } from 'src/screens/verification-failure'
import { Callback } from 'src/screens/callback'
import { useAuth } from '@ftdr/use-auth'
import { api } from 'src/utils/api'
import { IdentityProviders } from 'src/utils/shared-types'
import { Tracking } from 'src/screens/tracking'
import { AgentPurchase } from 'src/screens/agent-purchase'
import { Ecard } from 'src/screens/ecard'
import { ComparePage } from 'src/screens/compare-page/compare-page'
import { StatusPage } from 'src/screens/tracking-order-details/status-page'
import { AdminScreen } from 'src/screens/admin/admin'
import useError from 'src/hooks/use-error'
import { registry } from 'src/utils/api'
import { useDecision } from '@optimizely/react-sdk'
import { ApplianceCategoriesProvider } from 'src/hooks/use-categories-context'

export const AuthenticatedAgentRoute = (props: any) => {
  const { oidcUser, login } = useAuth()
  const e401 = useError(401, 'Invalid credentials')

  registry.registerMany({ '401': e401 })

  useEffect(() => {
    if (!oidcUser?.expires_in) return
    const interval = setTimeout(() => {
      login(IdentityProviders.Azure)
    }, oidcUser.expires_in * 1000)
    return () => {
      if (interval) clearTimeout(interval)
    }
  }, [oidcUser, login])

  if (!oidcUser || oidcUser?.expired) {
    login(IdentityProviders.Azure)
    return null
  }
  api.idToken = oidcUser.id_token
  api.contractServiceInit()
  return <Route {...props} />
}

export const AppliancePortalRoutes = () => {
  const history = useHistory()

  const [isOptiFlagOn] = useDecision('ecard_whitelist')
  const [isOptiAdmin] = useDecision('ap_admins')

  useEffect(() => {
    // Taelium view trigger
    const unregisterListener = history.listen(() => {
      window.utag?.view({
        virtual_page_path: window.location.pathname,
        virtual_page_url: window.location.href,
      })
    })
    return unregisterListener
  }, [history])

  return (
    <ApplianceCategoriesProvider>
      <Switch>
        <Route path="/callback" exact component={Callback} />
        <Route path="/login" exact component={Login} />
        <AuthenticatedAgentRoute
          path="/queue"
          exact
          component={QueueTabsLayout}
        />
        <AuthenticatedAgentRoute path="/agent-faq" exact component={AgentFaq} />
        <AuthenticatedAgentRoute
          path="/make-and-model"
          exact
          component={MakeAndModel}
        />
        <AuthenticatedAgentRoute path="/" exact component={AgentSurveyView} />
        <AuthenticatedAgentRoute path="/browse" exact component={Browse} />
        {isOptiAdmin.enabled && (
          <AuthenticatedAgentRoute
            path="/admin"
            exact
            component={AdminScreen}
          />
        )}
        {isOptiFlagOn.enabled && (
          <AuthenticatedAgentRoute path="/ecard" exact component={Ecard} />
        )}
        <AuthenticatedAgentRoute
          path="/compare"
          exact
          component={ComparePage}
        />
        <AuthenticatedAgentRoute
          path="/payment"
          exact
          component={OutrightPayment}
        />
        {/*Temproray route for payments page */}
        {api.config.ENV === 'development' || api.config.ENV === 'test' ? (
          <AuthenticatedAgentRoute
            path="/temp-payment"
            exact
            component={Payments}
          />
        ) : null}
        <AuthenticatedAgentRoute
          path="/browse/:productId"
          exact
          component={ProductDetailPage}
        />
        <AuthenticatedAgentRoute
          path="/browse/:productId/confirm"
          exact
          component={AgentReplacementConfirmationView}
        />
        <AuthenticatedAgentRoute
          path="/browse/:productId/confirm/purchase"
          exact
          component={AgentPurchase}
        />
        <AuthenticatedAgentRoute path="/browse/:productId/agent" />
        <Route path="/status" exact component={Tracking} />
        <Route
          path="/:replacementId/verification-failure"
          exact
          component={VerificationFailure}
        />
        <Route path="/:replacementId/shop" exact component={Browse} />
        <Route
          path="/:replacementId/shop/:productId"
          exact
          component={ProductDetailPage}
        />
        <Route
          path="/:replacementId/shop/:productId/checkout"
          exact
          component={CheckoutPage}
        />
        <Route
          path="/:replacementId/shop/:productId/checkout/confirmation"
          exact
          component={ConfirmationPage}
        />
        <Route path="/:replacementId/compare" exact component={ComparePage} />
        <Route path="/:replacementId/status" exact component={StatusPage} />
      </Switch>
    </ApplianceCategoriesProvider>
  )
}
