import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useForm, Controller } from 'react-hook-form'
import { AppState, MenuItem } from 'src/utils/shared-types'
import { initValues } from './initial-values'
import { labelsKeys } from '../../form/default-values'
import {
  IconButton,
  IconSearch,
  Input,
  Select,
  SelectHandler,
  SelectOption,
  useAppContext,
} from '@ftdr/blueprint-components-react'
import {
  ButtonComponent as Button,
  ProgressIndicatorComponent as ProgressIndicator,
} from 'src/components/custom-fdr-components'
import './reset.css'
import { emptyItem } from 'src/components/adminForms/initials'
import { states } from 'src/utils/states'
import { useGetVendors } from 'src/hooks/use-get-vendors'
import ShoppingCart from '../../shopping-cart/shopping-cart'
import {
  IShoppingCartData,
  useShoppingCartContext,
} from 'src/hooks/use-shopping-cart-context'
import { api } from 'src/utils/api'
import { continueReplacementSchema } from './validation'
import { yupResolver } from '@hookform/resolvers/yup'
import { usePaymentContext } from 'src/hooks/use-payment-context'
import { PaymentsMethodMfeExposedApi } from '@ftdr/payment-method-micro-frontend'
import PaymentsForm from '../../stripe-sub-form/stripe-sub-form'
import { Agreements } from '../../agreements/agreements'
import { formatItemsToShoppingCartData } from '../../utils/helpers'
import SkuSearch from '../../sku-search/sku-search'
import { usePayments } from 'src/hooks/use-temp-payments'
import { useSelector, useDispatch } from 'react-redux'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import { removeReplacement } from 'src/store/replacemnt-survey-store'

interface IForm {
  replacementID: string
  dispatchID: string
  contractID: string
  firstName: string
  lastName: string
  billingState: MenuItem
  vendor: MenuItem
  sku: string
  agreements: boolean
}

const init = initValues

const ContiniueReplacementForm = () => {
  const {
    appSettings: { localizedText },
  } = useAppContext()

  const dispatch = useDispatch()
  const surveyReplacement = useSelector(
    (state: AppState) => state.replacementSurvey.replacement
  )
  const { getVendors } = useGetVendors()
  const { submitStripePayment } = usePayments()
  const { status: paymentStatus, errorMsg } = usePaymentContext()
  const submitPaymentRef: RefObject<PaymentsMethodMfeExposedApi> = useRef(null)

  const [isReplacementInfoLoading, setIsReplacementInfoLoading] = useState<
    boolean
  >(false)
  const [isShoppingCartLoading, setIsShoppingCartLoading] = useState<boolean>(
    false
  )
  const [shoppingCartError, setShoppingCartError] = useState<string>('')

  const [adressID, setAdressID] = useState<string>('')
  const [cil, setCil] = useState<number>(0)
  const [replacementError, setReplacementError] = useState<string>('')

  const {
    setShoppingCartData,
    resetShoppingCartData,
    isShoppingCartNotEmpty,
    addItemToShoppingCart,
    vendorServiceCode,
    total,
    shoppingCartData,
    tax,
  } = useShoppingCartContext()

  const {
    watch,
    reset,
    setValue,
    clearErrors,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IForm>({
    mode: 'onTouched',
    resolver: yupResolver(continueReplacementSchema),
    defaultValues: initValues,
  })

  const replacementID = watch('replacementID')
  const sku = watch('sku')
  const { id: vendor } = watch('vendor')

  const submitForm = async (data: IForm) => {
    const cart = shoppingCartData
      .filter((v) => v.item.quantity)
      .map(({ item, price }) => ({
        ...item,
        ...price,
      }))

    const dataEntry = {
      cartItems: cart,
      taxDetail: tax,
    }

    submitStripePayment(submitPaymentRef, {
      ...data,
      paymentType: '2',
      cart: dataEntry,
      amount: `${total / 100}`,
      billingState: data.billingState.id,
      vendor: data.vendor.id,
      orderID: '',
      date: new Date(),
    })
  }
  const setAgreements = useCallback((v: boolean) => {
    setValue('agreements', v)
    v && clearErrors('agreements')
    //eslint-disable-next-line
  }, [])

  const setReplacementInfo = async () => {
    clearErrors('agreements')
    if (!replacementID) return
    resetShoppingCartData()
    setIsReplacementInfoLoading(true)
    try {
      const data = await api.getReplacements({ replacement_id: replacementID })
      const info = data.replacements[0]

      if (!info || Object.keys(info).length === 0) {
        setReplacementError('Not found')
        setIsReplacementInfoLoading(false)
        setValue('firstName', '')
        setValue('contractID', '')
        setValue('dispatchID', '')
        setValue('lastName', '')
        setValue('billingState', emptyItem)
        return
      }
      setAdressID(info.addressID || '')
      await getProducts(
        info?.product?.ID || info?.purchase?.productID || '',
        info?.addressID || ''
      )

      setReplacementError('')
      await setAllFields(info)
    } catch (e: any) {
      setIsReplacementInfoLoading(false)
      setReplacementError(e.message)
      console.log(e.message)
    }
  }

  const getProducts = async (productID: string, adressID: string) => {
    try {
      setShoppingCartError('')
      setIsShoppingCartLoading(true)
      const data = await api.getCartProducts(productID, {
        address_id: adressID,
      })
      setIsShoppingCartLoading(false)
      if (!data) return
      const newData = formatItemsToShoppingCartData(data)

      setShoppingCartData(newData as IShoppingCartData[], data.taxDetail!)
    } catch (e: any) {
      setShoppingCartError(
        e.message || 'Something went wrong during cart data feching'
      )
      setIsShoppingCartLoading(false)
    }
  }

  useEffect(() => {
    paymentStatus === 'success' && reset(initValues)
    //eslint-disable-next-line
  }, [paymentStatus])
  useEffect(() => {
    if (cil === 0) return
    addItemToShoppingCart({
      sku: `${vendorServiceCode}-CIL`,
      vendor: vendorServiceCode || '',
      unitPrice: -cil,
      isTax: false,
      category: 'SERVICE CODE',
      subcategory: 'Cash in lieu',
      description: 'Cash in lieu',
      isAvailableForZip: true,
    })
    //eslint-disable-next-line
  }, [cil])
  const getShoppingCart = async (productID: string, adressID: string) => {
    setIsReplacementInfoLoading(true)
    await getProducts(productID, adressID)
    setIsReplacementInfoLoading(false)
  }

  const setAllFields = async (data: appliancepb.IFullReplacementDetails) => {
    const state = states.find(
      (state) =>
        state.id === data?.customer?.billingAddress?.state ||
        state.id === data?.customer?.deliveryAddress?.state
    )
    const vendor = getVendors().find(
      (v) => v.id === data?.product?.ID?.slice(0, 2)
    )
    setAdressID(data?.addressID || '')
    setCil(data?.cashInLieu || 0)
    setValue('replacementID', data?.ID || '')
    setValue('firstName', data?.customer?.firstName || '')
    setValue('lastName', data?.customer?.lastName || '')
    setValue('contractID', data?.contractID || '')
    setValue('dispatchID', data?.dispatchID || '')
    setValue('billingState', state || emptyItem)
    setValue('vendor', vendor || emptyItem)
    await getShoppingCart(
      data?.product?.ID || data?.purchase?.productID || '',
      data?.addressID || ''
    )
  }
  useEffect(() => {
    if (surveyReplacement) {
      setAllFields(surveyReplacement)
    }
    return () => {
      dispatch(removeReplacement())
    }
    //eslint-disable-next-line
  }, [surveyReplacement])

  return (
    <div className="flex flex-col items-center sm:block  m-auto lg:m-0 overflow-visible">
      <div className="max-w-sm md:max-w-lg">
        <Controller
          name="replacementID"
          control={control}
          render={({ field }) => (
            <div className="w-full sm:w-auto md:w-56 mb-6 relative">
              <Input
                id="continue-replacement-form-rid"
                error={errors.replacementID?.message || replacementError}
                value={field.value}
                formField
                formFieldClassName="w-full sm:w-auto md:w-56 mb-6"
                label={localizedText('OUTRIGHT_PAYMENT_REPLACEMENT_LABEL')}
                className="w-full search-custom-input"
                onChange={field.onChange}
                onBlur={field.onBlur}
                disabled={isReplacementInfoLoading}
                onKeyUp={(e) => {
                  if (e.key === 'Enter' || e.keyCode === 13) {
                    setReplacementInfo()
                  }
                }}
              />
              <div
                className="absolute overflow-hidden flex justify-center items-center"
                style={{
                  width: '42px',
                  height: '42px',
                  right: '3px',
                  top: '24px',
                }}
              >
                <IconButton
                  color="interactive"
                  shape="rounded"
                  variant="ghost"
                  disabled={!replacementID || !!errors.replacementID?.message}
                  loading={isReplacementInfoLoading}
                  size="medium"
                  label={localizedText('SURVEY_DISPATCH_SEARCH_BTN')}
                  icon={<IconSearch size="23" />}
                  onClick={setReplacementInfo}
                />
              </div>
            </div>
          )}
        />
        {Object.keys(init)
          .filter(
            (v) => v !== 'sku' && v !== 'replacementID' && v !== 'agreements'
          )
          ?.map((v: any) => {
            return v !== 'billingState' && v !== 'vendor' ? (
              <Controller
                key={v}
                name={v}
                control={control}
                render={({ field }) => (
                  <Input
                    id={`continue-replacement-form-input-${v}`}
                    error={errors?.[v]?.message}
                    value={field.value}
                    formField
                    formFieldClassName="w-full sm:w-auto md:w-56 mb-6"
                    label={localizedText(labelsKeys[v])}
                    className="w-full"
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    disabled={true}
                  />
                )}
              />
            ) : (
              <Controller
                key={v}
                name={v}
                control={control}
                render={({ field }) => (
                  <Select
                    id={`continue-replacement-form-select-${v}`}
                    autoComplete={false}
                    error={errors[v]?.message}
                    selected={field.value}
                    formField
                    label={localizedText(labelsKeys[v])}
                    className="w-full md:w-56 mb-6"
                    options={v === 'billingState' ? states : []}
                    onSelect={field.onChange as SelectHandler<SelectOption>}
                    onBlur={field.onBlur}
                    placeholder="Select an option"
                    disabled={true}
                  />
                )}
              />
            )
          })}
      </div>
      <SkuSearch
        propTenant={{ id: 'AHS', label: 'AHS', value: 'AHS' }}
        isReplacementInfoLoading={isReplacementInfoLoading}
        error={errors.sku?.message || ''}
        control={control}
        vendor={vendor}
        params={{ address_id: adressID }}
        sku={sku}
        isContinueReplacement={true}
        resetField={() => setValue('sku', '')}
      />

      <div className="mt-8 mb-6" style={{ maxWidth: 1200 }}>
        {!isShoppingCartLoading && shoppingCartError === '' ? (
          <ShoppingCart />
        ) : isShoppingCartLoading ? (
          <ProgressIndicator data-testid="spinner" size="small" />
        ) : (
          <p className="text-error">{shoppingCartError}</p>
        )}
      </div>
      {!isReplacementInfoLoading && shoppingCartData.length > 0 ? (
        <Agreements updateFormState={setAgreements} />
      ) : null}
      {errors.agreements ? (
        <p className="text-error my-4">{localizedText('ERROR_AGREEMENTS')}</p>
      ) : null}
      {total > 0 ? (
        <div className="my-6">
          <PaymentsForm error={errorMsg} submitPaymentRef={submitPaymentRef} />
        </div>
      ) : null}

      <Button
        id="sku-card-form-submit"
        label={localizedText('BUTTON_SUBMIT')}
        className="w-auto flex justify-center lg:inline-block mt-4"
        onClick={handleSubmit(submitForm)}
        style={{ lineHeight: 1 }}
        disabled={!isShoppingCartNotEmpty}
      />
    </div>
  )
}

export default ContiniueReplacementForm
