import { Dialog } from '@ftdr/blueprint-components-react'
import {
  ButtonComponent as Button,
  TextComponent as Text,
} from 'src/components/custom-fdr-components'
import React, { useEffect, useState } from 'react'
import { useShoppingCartContext } from 'src/hooks/use-shopping-cart-context'

interface IProps {
  callback: () => void
}
const CartAvailabilityModal: React.FC<IProps> = ({ callback }) => {
  const { hasCartNotAvailableItems } = useShoppingCartContext()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  useEffect(() => {
    hasCartNotAvailableItems && setIsModalOpen(true)
  }, [hasCartNotAvailableItems])

  return (
    <Dialog
      id="cart-check-dialog"
      open={isModalOpen}
      modal={true}
      onClose={() => setIsModalOpen(false)}
      className="mt-auto mb-auto"
      usePortal={true}
      actions={[
        <Button
          size="small"
          label="Continue"
          onClick={() => {
            callback()
            setIsModalOpen(false)
          }}
        />,
        <Button variant="outlined" color="gray" size="small" label="Cancel" />,
      ]}
    >
      <Text textTemplateKey="PAYMENTS_AVAILABILITY_MESSAGE" />
    </Dialog>
  )
}

export default CartAvailabilityModal
