import React, { useRef, useEffect } from 'react'
import {
  IconButton,
  IconTrash,
  IconEdit,
  Dialog,
  useAppContext,
} from '@ftdr/blueprint-components-react'
import {
  ButtonComponent as Button,
  TextComponent as Text,
  ProgressIndicatorComponent as ProgressIndicator,
} from '../../custom-fdr-components'
import { initialBannersMessage } from '../initials'
import { AppState } from 'src/utils/shared-types'
import { useSelector } from 'react-redux'
import NotificationMessage from './notification-message'
import { useDispatch } from 'react-redux'
import {
  clearBannerDeleted,
  clearBannerEdited,
  clearDeleteError,
  clearEditError,
  deleteBanner,
  getBanners,
} from 'src/store/admin-store'
import { useAdminContext } from '../../../hooks/use-admin-context'
import { isCurrentRangeDate } from '../helpers'
import { getStatus } from '../helpers'
import { useMedia } from 'src/hooks/use-media'
import './reset.css'
import { isHSA } from 'src/utils/tenant-helper'

const ScheduledBanners = () => {
  const isTablet = useMedia('(min-width:768px)')
  const bannerToDelete = useRef<string | null | undefined>()

  const {
    setIsEditBannerModalOpen,
    saveBanner,
    setBannerCustomMessage,
    isDeleteBannerConfirmation,
    setIsDeleteBannerConfirmation,
  } = useAdminContext()
  const dispatch = useDispatch()
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const {
    banners,
    getLoading,
    getError,
    deleteError,
    isBannerDeleted,
    deleteLoading,
  } = useSelector((state: AppState) => state.admin)
  useEffect(() => {
    dispatch(getBanners())
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    !deleteError && isBannerDeleted && setIsDeleteBannerConfirmation(false)
    //eslint-disable-next-line
  }, [deleteError, isBannerDeleted])

  return (
    <div className="py-6">
      <div className="flex mb-8">
        <div
          className="w-full"
          style={{
            maxWidth: 560,
          }}
        >
          <Text
            textTemplateKey="ADMIN_TITLE_BANNERS_1"
            className="font-display font-bold text-4 lg:text-5 m-0"
          />
        </div>
        <div
          className="mb-3 hidden xl:flex  grow pl-4"
          style={{ flexGrow: 1, maxWidth: 560 }}
        >
          <div className="col px-2 w-1/3">
            <h3 className="mb-2 text-primary">Begin</h3>
          </div>
          <div className="col px-2 w-1/3">
            <h3 className="mb-2 text-primary">End</h3>
          </div>
          <div className="col px-2 w-1/3">
            <h3 className="mb-2 text-primary">Actions</h3>
          </div>
        </div>
      </div>
      {!!getError && (
        <p id="sheduled-banners-error" className="text-error">
          {getError}
        </p>
      )}
      {getLoading && (
        <div className="flex justify-start" id="sheduled-banners-progress">
          <ProgressIndicator variant="circular" size="small" />
        </div>
      )}
      {!getError && !getLoading && banners.length > 0
        ? banners.map((banner) => (
            <div
              id={`sheduled-banners-error${banner.id}`}
              className="mb-8 xl:flex flex-row-reverse justify-end"
              key={banner.id}
            >
              <div
                className="flex flex-wrap  mb-3 grow xl:pl-4"
                style={{ flexGrow: 1, maxWidth: 560 }}
              >
                <div className="col pr-2 xl:px-2 w-1/3">
                  <Text
                    id="sheduled-banners-begin-date"
                    className="mb-2 xl:hidden"
                  >
                    Begin
                  </Text>

                  {isCurrentRangeDate(
                    new Date(
                      (banner.activate?.seconds! * 1000) as number
                    ).getTime(),
                    new Date(
                      (banner.expire?.seconds! * 1000) as number
                    ).getTime()
                  ) ? (
                    <Text>now</Text>
                  ) : (
                    <>
                      <Text id="sheduled-banners-date">{`${new Date(
                        (banner.activate?.seconds! * 1000) as number
                      ).toLocaleDateString('en-US')}`}</Text>
                      <Text>{`${new Date(
                        (banner.activate?.seconds! * 1000) as number
                      ).toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                      })}`}</Text>
                    </>
                  )}
                </div>
                <div className="col px-2 w-1/3">
                  <Text
                    id="sheduled-banners-end-date"
                    className="mb-2 xl:hidden inline"
                  >
                    End
                  </Text>

                  <Text className="block">{`${new Date(
                    (banner.expire?.seconds! * 1000) as number
                  ).toLocaleDateString('en-US')}`}</Text>
                  <Text className="block">{`${new Date(
                    (banner.expire?.seconds! * 1000) as number
                  ).toLocaleTimeString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                  })}`}</Text>
                </div>

                <div className="col py-2 hidden xl:flex xl:px-2 items-start">
                  <IconButton
                    id="sheduled-banners-edit"
                    className="mr-4"
                    color={isHSA() ? 'primary' : 'interactive'}
                    variant="outlined"
                    label={localizedText('ADMIN_LABEL_EDIT')}
                    icon={<IconEdit />}
                    size="small"
                    onClick={() => {
                      if (
                        banner?.message &&
                        !initialBannersMessage
                          .map((value) => value.label)
                          .includes(banner.message)
                      ) {
                        setBannerCustomMessage(banner.message)
                      }
                      saveBanner(banner)

                      dispatch(clearBannerEdited())
                      dispatch(clearEditError())
                      setIsEditBannerModalOpen(true)
                    }}
                  />
                  <IconButton
                    id="sheduled-banners-delete"
                    label={localizedText('ADMIN_LABEL_DELETE')}
                    color="error"
                    variant="outlined"
                    icon={<IconTrash />}
                    size="small"
                    onClick={() => {
                      bannerToDelete.current = banner.id
                      dispatch(clearBannerDeleted())
                      setIsDeleteBannerConfirmation(true)
                    }}
                  />
                </div>
              </div>

              <div
                id="sheduled-banners-notification"
                style={{
                  flexGrow: 1,
                  maxWidth: 560,
                  width: isTablet ? 560 : '100%',
                }}
              >
                <NotificationMessage
                  message={banner.message!}
                  status={getStatus(banner.severity!)}
                />
              </div>
              {/* Footer only for mobile */}
              <div className="py-2 flex xl:hidden">
                <IconButton
                  id="sheduled-banners-edit"
                  className="mr-4"
                  color="warning"
                  variant="outlined"
                  label={localizedText('ADMIN_LABEL_EDIT')}
                  icon={<IconEdit />}
                  size="small"
                  onClick={() => {
                    if (
                      banner?.message &&
                      !initialBannersMessage
                        .map((value) => value.label)
                        .includes(banner.message)
                    ) {
                      setBannerCustomMessage(banner.message)
                    }
                    saveBanner(banner)

                    dispatch(clearBannerEdited())
                    dispatch(clearEditError())
                    setIsEditBannerModalOpen(true)
                  }}
                />
                <IconButton
                  id="sheduled-banners-delete"
                  label={localizedText('ADMIN_LABEL_DELETE')}
                  color="error"
                  variant="outlined"
                  icon={<IconTrash />}
                  size="small"
                  onClick={() => {
                    bannerToDelete.current = banner.id
                    dispatch(clearBannerDeleted())
                    setIsDeleteBannerConfirmation(true)
                  }}
                />
              </div>
              {/* Footer end */}
            </div>
          ))
        : !getLoading &&
          !getError && (
            <Text
              id="sheduled-banners-error-get"
              textTemplateKey="ADMIN_TITLE_BANNERS_NO_FOUND"
              className="font-display mb-8 py-4"
            />
          )}
      <Dialog
        className="modal-reset"
        id="sheduled-banners-modal-reset"
        open={isDeleteBannerConfirmation}
        usePortal={true}
        onClose={() => {
          setIsDeleteBannerConfirmation(false)
          dispatch(clearDeleteError())
        }}
        header={<Text variant="heading-04">Confirm</Text>}
        actions={[
          <Button
            id="sheduled-banners-modal-ok"
            size="small"
            label="OK"
            loading={deleteLoading}
            onClick={() => {
              dispatch(deleteBanner(bannerToDelete.current!))
            }}
          />,
          <Button
            id="sheduled-banners-modal-cancel"
            autoFocus={true}
            variant="ghost"
            size="small"
            label="Cancel"
            onClick={() => {
              dispatch(clearDeleteError())
              setIsDeleteBannerConfirmation(false)
            }}
          />,
        ]}
        actionsAlign="left"
        modal={true}
        showBackdrop={true}
      >
        <>
          <div>Are you sure?</div>
          {!!deleteError && (
            <p
              id="sheduled-banners-error-delete"
              className="text-error pt-2 pb-1 font-smaller absolute"
            >
              {deleteError}
            </p>
          )}
        </>
      </Dialog>
    </div>
  )
}

export default ScheduledBanners
